<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
      >
      <!-- :tabOptions.sync="tabOptions" -->
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :chemData.sync="chemData"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        tabName: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'chemInfo',
      chemData: {
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        mdmChemMaterialMsdsId: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        preMdmChemMaterialId: '',  // 이전 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        deliveryVendorCd: '',  // 협력사명
        mfgVendorCd: '',  // 제조사명
        usageCd: null,  // 용도
        usageEtc: '',  // 용도 기타
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고환산계수
        inConversonUnitCd: '',  // 입고환산단위
        msdsNo: '',  // MSDS번호
        emergTel: '',  // 긴급연락처
        makeAddress: '',  // 제조사주소
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        colorClass: '',  // 색상
        warehousingTypeCd: null,  // 입고형태
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        explosionLimit: '',  // 폭발한계상한하한
        vaporPressure: '',  // 증기압
        twaStandart: '',  // 노출기준TWA
        characteristicValue: '',  // 특성치(경구경치흡입제외)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '', // 미사용 사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [ { name: 'chemInfo', icon: 'info', label: '화학자재 기본정보', component: () => import(`${'./chemInfo.vue'}`) }]
      if (this.popupParam.mdmChemMaterialId != '') {
          _items.push({ name: 'chemEtc', icon: 'settings_applications', label: '화학자재 상세정보', component: () => import(`${'./chemEtc.vue'}`) });
          _items.push({ name: 'chemMsds', icon: 'security', label: '구성물질 정보/규제', component: () => import(`${'./chemComponents.vue'}`) });
          _items.push({ name: 'chemGhs', icon: 'gavel', label: 'GHS정보', component: () => import(`${'./chemGhs.vue'}`) });
          // _items.push({ name: 'chemPsm', icon: 'warning_amber', label: '유해화학물질(PSM) 정보', component: () => import(`${'./chemPsm.vue'}`) });
      }
      return _items;
    } 
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.chem.get.url;
      // code setting
      if (this.popupParam.tabName) {
        this.tab = this.popupParam.tabName;
      }
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.mdmChemMaterialId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmChemMaterialId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.chemData, _result.data);

          // this.$set(this.tabOptions['chemPsm'], 'tabLabelStyle', this.chemData.psmFlag != 'Y' ? 'text-decoration-line:line-through;text-decoration-color:#C10015' : '')
          // this.$set(this.tabItems[this.$_.findIndex(this.tabItems, { name: 'chemPsm' })], 'disabled', this.chemData.psmFlag != 'Y' ? true : false)

          // this.$set(this.tabOptions['chemGhs'], 'tabLabelStyle', this.chemData.ghsFlag != 'Y' ? 'text-decoration-line:line-through;text-decoration-color:#C10015' : '')
          // this.$set(this.tabItems[this.$_.findIndex(this.tabItems, { name: 'chemGhs' })], 'disabled', this.chemData.ghsFlag != 'Y' ? true : false)

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>